import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

//Gatsby
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Actions
import { saveLead } from "../../actions/saveLead";

//Components
import Loading from "../loading";
import ContactLeadForm from "../marketing/contactLeadForm";

//Redux
const mapStateToProps = ({
  isLoading,
  isLoadingDemo,
  demoVideoAccess,
  demoVideoSent,
}) => {
  return { isLoading, isLoadingDemo, demoVideoAccess, demoVideoSent };
};
const mapDispatchToProps = (dispatch) => ({
  saveLead: (userData) => {
    dispatch(saveLead(userData));
  },
});

const Snippet = ({
  // data,
  // currentPagePos,
  // setCurrentPagePos,
  // currentPageMode,
  // setCurrentPageMode,
  campaignId,
  setCampaignId,
  isLoadingDemo,
  demoData,
  saveLead,
  isLoading,
  demoVideoAccess,
  demoVideoSent,
}) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [formError, setFormError] = useState({});
  const [soundActivated, setSoundActivated] = useState("initial");
  const [videoUrl, setVideoUrl] = useState(
    "https://picovideomedia.s3.amazonaws.com/static/demo/202404/01-DPP-example-weartek.mp4"
  );

  const videoRef = useRef();

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load();
      videoRef.current.muted = false;
      videoRef.current.play();
    }
  }, [videoUrl]);

  const videoData = [
    {
      title: "DPP Example",
      src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/01-DPP-example-weartek.mp4",
    },
    // {
    //   title: "Custom-branded DPP",
    //   src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/02-custom-brand.mp4",
    // },
    {
      title: "DPP Authoring",
      src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/05-dpp-authoring.mp4",
    },
    {
      title: "DPP Publishing",
      src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/06-dpp-publishing.mp4",
    },
    {
      title: "Generative AI",
      src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/07-generative-ai.mp4",
    },
    {
      title: "Collections",
      src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/03-collections.mp4",
    },
    {
      title: "Blockchain vs. Cloud",
      src: "https://picovideomedia.s3.amazonaws.com/static/demo/202404/04-blockchain-cloud.mp4",
    },
  ];

  const handleSoundActivation = () => {
    if (soundActivated === false || soundActivated === "initial") {
      setSoundActivated(true);

      if (videoRef && videoRef.current) {
        videoRef.current.muted = false;
        videoRef.current.volume = 1;
      }
    } else {
      setSoundActivated(false);

      if (videoRef && videoRef.current) {
        // videoRef.current.muted = false;
        videoRef.current.volume = 0;
      }
    }
  };

  const validateSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    if (!userName) {
      setFormError({
        item: "userName",
        msg: "Please enter your name",
      });
    } else if (!userCompany) {
      setFormError({
        item: "userCompany",
        msg: "Please enter your company name",
      });
    } else if (!userEmail) {
      setFormError({
        item: "userEmail",
        msg: "Please enter your business email address",
      });
    } else if (!userCountry) {
      setFormError({
        item: "userCountry",
        msg: "Please select your country",
      });
    } else if (!emailRegex.test(userEmail)) {
      setFormError({
        item: "userEmail",
        msg: "Please enter a valid business email address",
      });
    } else {
      setFormError({});
      // alert("submit");
      saveLead({
        userEmail,
        userName,
        userCompany,
        userCountry,
        campaignId,
        leadSource: "video-demo",
        userLastName: "",
        userTitle: "",
        userPhone: "",
        userEmployees: "",
        userComments: "",
      });
    }
  };
  return (
    <>
      <div class="row my-0">
        <div class="col-12 col-lg-10 offset-lg-1 ">
          <>
            <div class="demo-subhead-mode-full">PicoNext Video Demo</div>
          </>
        </div>
      </div>
      {isLoadingDemo && (
        <div class="p-2 my-1 d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      )}
      {!demoVideoAccess && (
        <div class="row my-1">
          <div class="col-lg-7 offset-lg-1 p-4">
            {/* <p class="lead line-height-4 pb-3">
              In this free guide, get the latest trends on sustainability
              transparency from new survey research, along with ways that brands
              can transform their strategies to take advantage.
            </p> */}
            <div class="d-flex flex-row align-items-center justify-content-center mb-4">
              <StaticImage
                src="../../images/demo/202404/video-demo-poster.png"
                alt="Digital Product Passports Video Demo"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={400}
                className="mx-3"
                // height={400}
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>
                In this demo, explore the PicoNext Digital Product Passport
                platform, including two-minute videos on:
              </strong>
              <ul>
                <li>
                  Examples of different Digital Product Passports from different
                  brands
                </li>
                <li>
                  How you can use generative AI to accleerate your DPP workflow
                </li>
                <li>
                  A custom-branded DPP using PicoNext APIs to display
                  sustainability data
                </li>
                <li>
                  When to use blockchain DPPs, and when to use cloud-based DPPs
                </li>

                <li>
                  The DPP Planner capability of PicoNext, which allows you to
                  organize and manage your DPP sustainability data
                </li>
                <li>And more...</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 d-flex">
            <div class="d-flex flex-column align-items-stretch text-center border-1-blue-4 bg-blue-5 rounded corners mt-3 p-2">
              <h2 class="display-4 my-3 p-2">
                Please fill out the form below to access the video demo
              </h2>
              <input
                type="text"
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userName" && "border-2-red-1"
                }`}
                placeholder="Your name"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <input
                type="text"
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userCompany" && "border-2-red-1"
                }`}
                placeholder="Company name"
                value={userCompany}
                onChange={(e) => {
                  setUserCompany(e.target.value);
                }}
              />
              <input
                type="text"
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userEmail" && "border-2-red-1"
                }`}
                placeholder="Business email"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
              <select
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userCountry" && "border-2-red-1"
                }`}
                value={userCountry}
                onChange={(e) => {
                  setUserCountry(e.target.value);
                }}
              >
                {" "}
                <option value="">Select your country...</option>
                <option value="AF">Afghanistan</option>
                <option value="AX">Aland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Cote D'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curacao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and Mcdonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">
                  Korea, Democratic People's Republic of
                </option>
                <option value="KR">Korea, Republic of</option>
                <option value="XK">Kosovo</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthelemy</option>
                <option value="SH">Saint Helena</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="CS">Serbia and Montenegro</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.S.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
              <hr size={1} />
              {isLoading ? (
                <div class="p-2 m-1 d-flex align-items-center justify-content-center">
                  <Loading class="small" />
                </div>
              ) : null}
              <div class="d-flex align-items-center justify-content-center">
                {/* <button
                  class="btn btn-lg btn-link btn-link-demo-back mx-2 gray-1"
                  onClick={() => {
                    setCurrentPageMode("");
                  }}
                >
                  Back
                </button> */}
                <button
                  class="btn btn-lg btn-primary mx-2"
                  onClick={() => {
                    validateSubmit();
                  }}
                >
                  Watch demo
                </button>
              </div>
              {demoVideoSent && (
                <div class="p-2 mt-2 line-height-2">
                  Thanks! We've e-mailed a link to the video demo to you.
                </div>
              )}
              <hr size={1} />
              <div class="d-flex flex-row align-items-center justify-content-center my-3">
                <div class="gray-2 text-legal text-center line-height-1 px-2">
                  We'll use your email to provide occasional updates on Digital
                  Product Passport innovations and regulations. You can
                  unsubscribe at any time. For more information on how we
                  process your personal data, view our{" "}
                  <a href="/about/privacy" class="gray-2" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {demoVideoAccess && (
        <div class="row my-0">
          <div class="col-12 col-lg-10 offset-lg-1 ">
            {demoVideoSent && (
              <div class="w-100 p-2 text-center">
                We've also e-mailed a link to the demo to you
              </div>
            )}
            <div class="my-1 d-flex flex-column flex-md-row align-items-center align-items-md-start">
              <div class="video-demo-playlist p-3 m-1 rounded-corners d-flex flex-column align-items-center align-items-md-start">
                {videoData.map((videoItem) => (
                  <button
                    class={`btn btn-playlist ${
                      videoUrl === videoItem.src ? "active" : ""
                    }`}
                    onClick={() => {
                      setVideoUrl(videoItem.src);
                    }}
                  >
                    {videoItem.title}
                  </button>
                ))}
                <hr size={1} />
                {/* <Link
                  class="btn btn-demo-ai btn-outline-dkblue"
                  to="/demo/create-dpp"
                >
                  Create free sample DPP with AI
                </Link> */}
              </div>
              <div style={{ position: "relative" }}>
                <video
                  ref={videoRef}
                  class="video-preview m-1 video-preview-demo"
                  autoPlay="autoplay"
                  muted
                  playsInline
                  // loop
                  controls
                  key={videoUrl}
                >
                  <source src={videoUrl} />
                </video>
                <button
                  onClick={() => {
                    handleSoundActivation();
                  }}
                  class="d-flex align-items-center justify-content-center btn bg-gray-7 ml-3"
                  style={{
                    border: 0,
                    background: "transparent",
                    position: "absolute",
                    // right: 130,
                    // marginTop: 10,
                    top: 10,
                    right: 10,
                    opacity: 0.6,
                  }}
                >
                  {soundActivated === "initial" ? (
                    <div>
                      {/* <VolumeAnimation /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M792-56 671-177q-25 16-53 27.5T560-131v-82q14-5 27.5-10t25.5-12L480-368v208L280-360H120v-240h128L56-792l56-56 736 736-56 56Zm-8-232-58-58q17-31 25.5-65t8.5-70q0-94-55-168T560-749v-82q124 28 202 125.5T840-481q0 53-14.5 102T784-288ZM650-422l-90-90v-130q47 22 73.5 66t26.5 96q0 15-2.5 29.5T650-422ZM480-592 376-696l104-104v208Zm-80 238v-94l-72-72H200v80h114l86 86Zm-36-130Z" />
                      </svg>
                    </div>
                  ) : soundActivated === false ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M280-360v-240h160l200-200v640L440-360H280Zm80-80h114l86 86v-252l-86 86H360v80Zm100-40Z" />
                      </svg>
                      {/* <MdVolumeOff
                      size={34}
                      style={{ marginTop: 6, marginBottom: 8 }}
                    /> */}
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M560-131v-82q90-26 145-100t55-168q0-94-55-168T560-749v-82q124 28 202 125.5T840-481q0 127-78 224.5T560-131ZM120-360v-240h160l200-200v640L280-360H120Zm440 40v-322q47 22 73.5 66t26.5 96q0 51-26.5 94.5T560-320ZM400-606l-86 86H200v80h114l86 86v-252ZM300-480Z" />
                      </svg>
                      {/* <MdVolumeUp
                      size={34}
                      style={{ marginTop: 6, marginBottom: 8 }}
                    /> */}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// export default Snippet;
export default connect(mapStateToProps, mapDispatchToProps)(Snippet);
