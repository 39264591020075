//React
import React from "react";

//Gatsby
import { useStaticQuery, graphql, Link } from "gatsby";

//Assets
import LogoReverse from "../../images/svg/piconext-logo-05.svg";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      industries: allSitePage(
        filter: { path: { glob: "/(industry)/*" } }
        sort: { path: ASC }
      ) {
        edges {
          node {
            path
            pageContext
          }
        }
      }
    }
  `);
  console.log("data--");
  console.log(data);
  return (
    <footer class="footer">
      <div class="container text-center text-md-start">
        <div class="row row-brand ps-0 ps-md-0">
          <div class="col-md-2 footer-brand-container d-flex align-items-center justify-content-center justify-content-md-start">
            <div class="footer-brand">
              <LogoReverse />
            </div>
          </div>
        </div>
        <div class="row row-menu d-flex flex-row align-items-start justify-content-start">
          <div class="col-md-3 col-menu pb-1 pb-md-0">
            <div class="menu-subhead">
              <Link to="/platform/">Platform</Link>
            </div>
            <ul class="menu menu-platform">
              {/* <li>
                  <Link to="/platform/at-a-glance/">At-a-glance</Link>
                </li> */}
              {/* <li>
                  <Link to="/platform/create-web3/">Design</Link>
                </li> */}
              <li>
                <Link to="/platform/create-dpp/">
                  Create Digital Product Passport
                </Link>
              </li>
              {/* <li>
                  <Link to="/platform/web3-onboarding/">Onboarding</Link>
                </li> */}

              {/* <li>
                  <Link to="/platform/communities/">Communities</Link>
                </li>
                <li>
                  <Link to="/platform/branded-web3-marketplaces/">
                    Marketplaces
                  </Link>
                </li> */}
              <li>
                <Link to="/platform/digital-product-passport-planner/">
                  Digital Product Passport Planner
                </Link>
              </li>
              <li>
                <Link to="/platform/generative-ai/">Generative AI</Link>
              </li>
              <li>
                <Link to="/platform/digital-product-passport-data-events/">
                  Digital Product Passport Data Events
                </Link>
              </li>
              <li>
                <Link to="/platform/loyalty/">Loyalty</Link>
              </li>
              <li>
                <Link to="/platform/token-explorer/">Token Explorer</Link>
              </li>
              <li>
                <Link to="/platform/">All capabilities</Link>
              </li>
              {/* <li>
                  <Link to="/about/how-works/">How it works</Link>
                </li> */}

              {/* <li>
                  <Link to="/about/examples/">Examples</Link>
                </li> */}
              {/* <li>
                  <Link to="/about/pricing/">Pricing</Link>
                </li> */}

              {/* <li>
                  <Link to="/about/tour/brands/">Tour</Link>
                </li> */}
              {/* <li>
                  <Link to="/about/referral/">Referral rewards</Link>
                </li> */}
            </ul>
          </div>
          <hr class="d-block d-md-none" />
          <div class="col-md-3 col-menu pb-1 pb-md-0">
            <div class="menu-subhead">
              <Link to="/solutions/">Solutions</Link>
            </div>
            <ul class="menu menu-platform">
              <li>
                <Link to="/solutions/digital-product-passport/">
                  Digital Product Passport
                </Link>
              </li>
              {/* <li>
                  <Link to="/solutions/web3-climate-sustainability/">
                    Sustainability Transparency
                  </Link>
                </li> */}

              <li>
                <Link to="/solutions/web3-customer-loyalty/">
                  Customer Loyalty
                </Link>
              </li>
              {/* <li>
                  <Link to="/solutions/web3-local-access-pass/">
                    Local Access Pass
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/web3-social-impact/">Social Impact</Link>
                </li>

                <li>
                  <Link to="/solutions/web3-events/">Events</Link>
                </li>
                <li>
                  <Link to="/solutions/authentication-ai-generated-content/">
                    Content Authentication
                  </Link>
                </li> */}
              <li>
                <Link to="/solutions/">All solutions</Link>
              </li>
            </ul>
          </div>
          <hr class="d-block d-md-none" />
          <div class="col-md-3 col-menu pb-1 pb-md-0">
            <div class="menu-subhead">Industries</div>
            <ul class="menu menu-platform">
              {data?.industries?.edges?.map((industry) => (
                <li>
                  <Link to={industry?.node?.path}>
                    Digital Product Passport for{" "}
                    {industry?.node?.pageContext?.title}
                  </Link>
                </li>
              ))}
              <li>
                <Link to="/industry/">All industries</Link>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-menu pb-1 pb-md-0">
            <div class="menu-subhead">
              <Link to="/demo/video/">Get started</Link>
            </div>
            <ul class="menu menu-help">
              {/* <li>
                  <Link to="/start/?c=sd-trial--ft">Free trial</Link>
                </li> */}
              <li>
                <Link to="/demo/video/">See demo</Link>
              </li>
              <li>
                <Link to="/request-consultation/">Request consultation</Link>
              </li>
              <li class="border-bottom-1-gray-1">
                <a
                  href={process.env.CONSOLE_DOMAIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign in
                </a>
              </li>
              <li>
                <Link to="/pricing/">Pricing</Link>
              </li>
              <li>
                <Link to="/resources/">Resources</Link>
              </li>
              <li>
                <a
                  href={process.env.SUPPORT_DOMAIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
              </li>
              {/* <li>
                <Link to="/prism/">Prism newsletter</Link>
              </li> */}
              <li>
                <Link to="/about/terms/">Terms</Link>
              </li>
              <li class="border-bottom-1-gray-1">
                <Link to="/about/privacy/">Privacy</Link>
              </li>
              {/* <li>
                  <Link to="/about/blockchain-sustainability/">
                    Blockchain sustainability
                  </Link>
                </li> */}
              {/* <hr size={1} class="bg-gray-1" /> */}
              <li>
                <Link to="/article/digital-product-passport/">
                  Digital Product Passport (2024)
                </Link>
              </li>
              <li>
                <Link to="/article/sustainable-fashion-digital-product-passport/">
                  Digital Product Passport: Fashion
                </Link>
              </li>
              <li>
                <Link to="/article/digital-product-passport-example/">
                  Digital Product Passport Example
                </Link>
              </li>
              <li>
                <Link to="/article/digital-battery-passport/">
                  Digital Battery Passport
                </Link>
              </li>
            </ul>
          </div>
          <hr class="d-block d-md-none" />
          {/* <div class="col-md-3 col-menu pb-1 pb-md-0">
            <div class="menu-subhead">&nbsp;</div>
            <ul class="menu menu-help">
              <li>
                <Link to="/about/terms/">Terms</Link>
              </li>
              <li>
                <Link to="/about/privacy/">Privacy</Link>
              </li>
            </ul>
          </div>
          <hr class="d-block d-md-none" /> */}
        </div>
        <div class="row row-copyright text-center text-md-start">
          <div class="col-md-12">&copy; 2024 PicoNext, Inc.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
