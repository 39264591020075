exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-blockchain-sustainability-js": () => import("./../../../src/pages/about/blockchain-sustainability.js" /* webpackChunkName: "component---src-pages-about-blockchain-sustainability-js" */),
  "component---src-pages-about-digital-product-passport-free-js": () => import("./../../../src/pages/about/digital-product-passport-free.js" /* webpackChunkName: "component---src-pages-about-digital-product-passport-free-js" */),
  "component---src-pages-activate-agreement-js": () => import("./../../../src/pages/activate/agreement.js" /* webpackChunkName: "component---src-pages-activate-agreement-js" */),
  "component---src-pages-activate-freetier-js": () => import("./../../../src/pages/activate/freetier.js" /* webpackChunkName: "component---src-pages-activate-freetier-js" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-video-js": () => import("./../../../src/pages/demo/video.js" /* webpackChunkName: "component---src-pages-demo-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-index-js": () => import("./../../../src/pages/industry/index.js" /* webpackChunkName: "component---src-pages-industry-index-js" */),
  "component---src-pages-landing-accesspass-js": () => import("./../../../src/pages/landing/accesspass.js" /* webpackChunkName: "component---src-pages-landing-accesspass-js" */),
  "component---src-pages-landing-catching-web-3-wave-js": () => import("./../../../src/pages/landing/catching-web3-wave.js" /* webpackChunkName: "component---src-pages-landing-catching-web-3-wave-js" */),
  "component---src-pages-landing-digital-product-passport-sustainability-js": () => import("./../../../src/pages/landing/digital-product-passport-sustainability.js" /* webpackChunkName: "component---src-pages-landing-digital-product-passport-sustainability-js" */),
  "component---src-pages-landing-generative-ai-content-authentication-js": () => import("./../../../src/pages/landing/generative-ai-content-authentication.js" /* webpackChunkName: "component---src-pages-landing-generative-ai-content-authentication-js" */),
  "component---src-pages-landing-web-3-climate-sustainability-js": () => import("./../../../src/pages/landing/web3-climate-sustainability.js" /* webpackChunkName: "component---src-pages-landing-web-3-climate-sustainability-js" */),
  "component---src-pages-landing-web-3-loyalty-usecases-js": () => import("./../../../src/pages/landing/web3-loyalty-usecases.js" /* webpackChunkName: "component---src-pages-landing-web-3-loyalty-usecases-js" */),
  "component---src-pages-nft-creator-js": () => import("./../../../src/pages/nft-creator.js" /* webpackChunkName: "component---src-pages-nft-creator-js" */),
  "component---src-pages-platform-at-a-glance-js": () => import("./../../../src/pages/platform/at-a-glance.js" /* webpackChunkName: "component---src-pages-platform-at-a-glance-js" */),
  "component---src-pages-platform-branded-web-3-marketplaces-js": () => import("./../../../src/pages/platform/branded-web3-marketplaces.js" /* webpackChunkName: "component---src-pages-platform-branded-web-3-marketplaces-js" */),
  "component---src-pages-platform-communities-js": () => import("./../../../src/pages/platform/communities.js" /* webpackChunkName: "component---src-pages-platform-communities-js" */),
  "component---src-pages-platform-create-dpp-js": () => import("./../../../src/pages/platform/create-dpp.js" /* webpackChunkName: "component---src-pages-platform-create-dpp-js" */),
  "component---src-pages-platform-create-web-3-js": () => import("./../../../src/pages/platform/create-web3.js" /* webpackChunkName: "component---src-pages-platform-create-web-3-js" */),
  "component---src-pages-platform-digital-product-passport-data-events-js": () => import("./../../../src/pages/platform/digital-product-passport-data-events.js" /* webpackChunkName: "component---src-pages-platform-digital-product-passport-data-events-js" */),
  "component---src-pages-platform-digital-product-passport-planner-js": () => import("./../../../src/pages/platform/digital-product-passport-planner.js" /* webpackChunkName: "component---src-pages-platform-digital-product-passport-planner-js" */),
  "component---src-pages-platform-generative-ai-js": () => import("./../../../src/pages/platform/generative-ai.js" /* webpackChunkName: "component---src-pages-platform-generative-ai-js" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-platform-loyalty-js": () => import("./../../../src/pages/platform/loyalty.js" /* webpackChunkName: "component---src-pages-platform-loyalty-js" */),
  "component---src-pages-platform-permissions-data-management-js": () => import("./../../../src/pages/platform/permissions-data-management.js" /* webpackChunkName: "component---src-pages-platform-permissions-data-management-js" */),
  "component---src-pages-platform-token-collections-js": () => import("./../../../src/pages/platform/token-collections.js" /* webpackChunkName: "component---src-pages-platform-token-collections-js" */),
  "component---src-pages-platform-token-explorer-js": () => import("./../../../src/pages/platform/token-explorer.js" /* webpackChunkName: "component---src-pages-platform-token-explorer-js" */),
  "component---src-pages-platform-web-3-onboarding-js": () => import("./../../../src/pages/platform/web3-onboarding.js" /* webpackChunkName: "component---src-pages-platform-web-3-onboarding-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-prism-index-js": () => import("./../../../src/pages/prism/index.js" /* webpackChunkName: "component---src-pages-prism-index-js" */),
  "component---src-pages-request-access-js": () => import("./../../../src/pages/request-access.js" /* webpackChunkName: "component---src-pages-request-access-js" */),
  "component---src-pages-request-consultation-js": () => import("./../../../src/pages/request-consultation.js" /* webpackChunkName: "component---src-pages-request-consultation-js" */),
  "component---src-pages-request-demo-content-authentication-js": () => import("./../../../src/pages/request-demo-content-authentication.js" /* webpackChunkName: "component---src-pages-request-demo-content-authentication-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-request-demo-local-access-pass-js": () => import("./../../../src/pages/request-demo-local-access-pass.js" /* webpackChunkName: "component---src-pages-request-demo-local-access-pass-js" */),
  "component---src-pages-request-demo-sustainability-action-report-js": () => import("./../../../src/pages/request-demo-sustainability-action-report.js" /* webpackChunkName: "component---src-pages-request-demo-sustainability-action-report-js" */),
  "component---src-pages-request-invite-dpp-planner-js": () => import("./../../../src/pages/request-invite-dpp-planner.js" /* webpackChunkName: "component---src-pages-request-invite-dpp-planner-js" */),
  "component---src-pages-request-invite-free-tier-js": () => import("./../../../src/pages/request-invite-free-tier.js" /* webpackChunkName: "component---src-pages-request-invite-free-tier-js" */),
  "component---src-pages-request-invite-js": () => import("./../../../src/pages/request-invite.js" /* webpackChunkName: "component---src-pages-request-invite-js" */),
  "component---src-pages-request-invite-nft-js": () => import("./../../../src/pages/request-invite-nft.js" /* webpackChunkName: "component---src-pages-request-invite-nft-js" */),
  "component---src-pages-request-pro-services-js": () => import("./../../../src/pages/request-pro-services.js" /* webpackChunkName: "component---src-pages-request-pro-services-js" */),
  "component---src-pages-request-shopify-access-js": () => import("./../../../src/pages/request-shopify-access.js" /* webpackChunkName: "component---src-pages-request-shopify-access-js" */),
  "component---src-pages-solutions-authentication-ai-generated-content-js": () => import("./../../../src/pages/solutions/authentication-ai-generated-content.js" /* webpackChunkName: "component---src-pages-solutions-authentication-ai-generated-content-js" */),
  "component---src-pages-solutions-digital-product-passport-js": () => import("./../../../src/pages/solutions/digital-product-passport.js" /* webpackChunkName: "component---src-pages-solutions-digital-product-passport-js" */),
  "component---src-pages-solutions-digital-product-passport-sustainability-blockchain-faq-js": () => import("./../../../src/pages/solutions/digital-product-passport/sustainability-blockchain-faq.js" /* webpackChunkName: "component---src-pages-solutions-digital-product-passport-sustainability-blockchain-faq-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-sustainability-certification-js": () => import("./../../../src/pages/solutions/sustainability-certification.js" /* webpackChunkName: "component---src-pages-solutions-sustainability-certification-js" */),
  "component---src-pages-solutions-web-3-climate-sustainability-js": () => import("./../../../src/pages/solutions/web3-climate-sustainability.js" /* webpackChunkName: "component---src-pages-solutions-web-3-climate-sustainability-js" */),
  "component---src-pages-solutions-web-3-customer-loyalty-js": () => import("./../../../src/pages/solutions/web3-customer-loyalty.js" /* webpackChunkName: "component---src-pages-solutions-web-3-customer-loyalty-js" */),
  "component---src-pages-solutions-web-3-events-js": () => import("./../../../src/pages/solutions/web3-events.js" /* webpackChunkName: "component---src-pages-solutions-web-3-events-js" */),
  "component---src-pages-solutions-web-3-local-access-pass-js": () => import("./../../../src/pages/solutions/web3-local-access-pass.js" /* webpackChunkName: "component---src-pages-solutions-web-3-local-access-pass-js" */),
  "component---src-pages-solutions-web-3-social-impact-js": () => import("./../../../src/pages/solutions/web3-social-impact.js" /* webpackChunkName: "component---src-pages-solutions-web-3-social-impact-js" */),
  "component---src-pages-subscription-confirm-js": () => import("./../../../src/pages/subscription/confirm.js" /* webpackChunkName: "component---src-pages-subscription-confirm-js" */),
  "component---src-templates-about-template-js-content-file-path-src-markdown-privacy-mdx": () => import("./../../../src/templates/aboutTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/privacy.mdx" /* webpackChunkName: "component---src-templates-about-template-js-content-file-path-src-markdown-privacy-mdx" */),
  "component---src-templates-about-template-js-content-file-path-src-markdown-terms-mdx": () => import("./../../../src/templates/aboutTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/terms.mdx" /* webpackChunkName: "component---src-templates-about-template-js-content-file-path-src-markdown-terms-mdx" */),
  "component---src-templates-about-template-js-content-file-path-src-markdown-terms-rewards-mdx": () => import("./../../../src/templates/aboutTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/terms-rewards.mdx" /* webpackChunkName: "component---src-templates-about-template-js-content-file-path-src-markdown-terms-rewards-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220207-nfts-for-brands-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220207-nfts-for-brands.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220207-nfts-for-brands-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220221-how-to-create-nft-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220221-how-to-create-nft.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220221-how-to-create-nft-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220404-nfts-part-of-marketing-strategy-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220404-nfts-part-of-marketing-strategy.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220404-nfts-part-of-marketing-strategy-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220405-different-types-of-nfts-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220405-different-types-of-nfts.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220405-different-types-of-nfts-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220407-nft-brand-database-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220407-nft-brand-database.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220407-nft-brand-database-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220517-travel-hospitality-nft-ideas-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220517-travel-hospitality-nft-ideas.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220517-travel-hospitality-nft-ideas-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220519-moving-beyond-collectibles-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220519-moving-beyond-collectibles.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220519-moving-beyond-collectibles-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220805-social-impact-nfts-five-tips-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220805-social-impact-nfts-five-tips.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220805-social-impact-nfts-five-tips-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220812-digital-postcard-nfts-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220812-digital-postcard-nfts.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220812-digital-postcard-nfts-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220822-utility-nfts-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220822-utility-nfts.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220822-utility-nfts-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220829-reprint-nft-collectibles-brand-engagement-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220829-reprint-nft-collectibles-brand-engagement.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220829-reprint-nft-collectibles-brand-engagement-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20220906-nft-experience-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220906-nft-experience.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20220906-nft-experience-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20221005-web-3-survey-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20221005-web3-survey.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20221005-web-3-survey-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20221108-loyalty-features-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20221108-loyalty-features.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20221108-loyalty-features-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20221215-sustainability-travel-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20221215-sustainability-travel.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20221215-sustainability-travel-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230223-loyalty-redemption-features-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230223-loyalty-redemption-features.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230223-loyalty-redemption-features-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230310-piconft-now-piconext-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230310-piconft-now-piconext.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230310-piconft-now-piconext-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230314-web-3-usecases-loyalty-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230314-web3-usecases-loyalty.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230314-web-3-usecases-loyalty-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230503-brand-content-authentication-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230503-brand-content-authentication.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230503-brand-content-authentication-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230606-climate-action-report-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230606-climate-action-report.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230606-climate-action-report-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230717-sustainability-marketing-solution-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230717-sustainability-marketing-solution.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230717-sustainability-marketing-solution-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230904-digital-product-passport-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230904-digital-product-passport.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230904-digital-product-passport-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20230927-digital-product-passport-fashion-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20230927-digital-product-passport-fashion.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20230927-digital-product-passport-fashion-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20231003-dpp-example-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20231003-dpp-example.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20231003-dpp-example-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20231023-token-apis-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20231023-token-apis.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20231023-token-apis-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20231115-dpp-survey-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20231115-dpp-survey.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20231115-dpp-survey-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20231205-medium-dpp-fashion-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20231205-medium-dpp-fashion.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20231205-medium-dpp-fashion-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-bonandberg-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240117-customer-bonandberg.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-bonandberg-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-momentum-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240117-customer-momentum.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-momentum-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-simplechic-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240117-customer-simplechic.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-simplechic-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-tammam-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240117-customer-tammam.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-tammam-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-themorphbag-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240117-customer-themorphbag.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240117-customer-themorphbag-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240131-customer-tomorrowsair-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240131-customer-tomorrowsair.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240131-customer-tomorrowsair-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240131-tomorrows-air-announce-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240131-tomorrows-air-announce.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240131-tomorrows-air-announce-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240214-medium-dpp-timeline-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240214-medium-dpp-timeline.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240214-medium-dpp-timeline-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240221-digital-battery-passport-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240221-digital-battery-passport.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240221-digital-battery-passport-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240301-dpp-infographic-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240301-DPP-infographic.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240301-dpp-infographic-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240312-dpp-freetier-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240312-DPP-freetier.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240312-dpp-freetier-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240402-support-dpp-cloud-tutorial-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240402-support-dpp-cloud-tutorial.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240402-support-dpp-cloud-tutorial-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240414-youtube-dpp-blockchain-cloud-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240414-youtube-dpp-blockchain-cloud.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240414-youtube-dpp-blockchain-cloud-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240425-youtube-dpp-generativeai-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240425-youtube-dpp-generativeai.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240425-youtube-dpp-generativeai-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240522-eu-espr-webinar-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240522-EU-ESPR-webinar.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240522-eu-espr-webinar-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240606-restricted-data-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240606-restricted-data.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240606-restricted-data-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240613-item-batch-data-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240613-item-batch-data.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240613-item-batch-data-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240619-ai-assistant-announcement-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240619-ai-assistant-announcement.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240619-ai-assistant-announcement-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240621-customer-weartek-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240621-customer-weartek.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240621-customer-weartek-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240829-blockchain-digital-product-passport-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240829-blockchain-digital-product-passport.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240829-blockchain-digital-product-passport-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-src-markdown-20240830-implementation-digital-product-passport-mdx": () => import("./../../../src/templates/blogTemplate.js?__contentFilePath=/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20240830-implementation-digital-product-passport.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-markdown-20240830-implementation-digital-product-passport-mdx" */),
  "component---src-templates-blog-template-list-js": () => import("./../../../src/templates/blogTemplateList.js" /* webpackChunkName: "component---src-templates-blog-template-list-js" */),
  "component---src-templates-planner-category-template-js": () => import("./../../../src/templates/plannerCategoryTemplate.js" /* webpackChunkName: "component---src-templates-planner-category-template-js" */)
}

